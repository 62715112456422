import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { abi as IUniswapV2FactoryABI } from '@linqto-team/amm-core/build/IUniswapV2Factory.json'
import { FACTORY_ADDRESS } from '@linqto-team/amm-sdk'
import { useSingleCallResult } from '../state/multicall/hooks'

export function usePoolFee(): number | null {
  const { library } = useWeb3React()

  const contract = useMemo(() => {
    if (!library) return null
    return new Contract(FACTORY_ADDRESS, IUniswapV2FactoryABI, library)
  }, [library])

  const poolFeeResult = useSingleCallResult(contract, 'poolFee')
  const poolFee = poolFeeResult.result?.[0]
  return poolFee
}
